import * as React from "react";

import {BaseComponent} from '../base/baseComponent';
import { NSAjaxReact } from 'nscomponentsreact';
import { NSGridReact, INSGridReactSettings } from 'nscomponentsreact';
import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting } from 'nscomponentsreact';
import { PetComponentRenderer } from './components/petComponentRenderer';
import {DataGenerator} from '../../util/dataGenerator';
import { CountryRenderer } from './components/countryRenderer';
import { CountryRenderer1 } from "./components/countryRenderer1";


export class HierarchicalGridDemo extends BaseComponent
{
    private grid: NSGridReact;
    private dataGenerator: DataGenerator;

    constructor(public props: any, public state: any) 
    {        
        super(props,state);
        const customClass: INSGridCustomClassSetting = {headerCell:"columnClass",nonFirstBodyColumn:"columnClass"};
        
        /*const gridColumn: INSGridColumn[] = [
                                                  {headerText: "First Name", dataField: "firstName", width: "20%",filter:{advancedFilterPopUpPos: "bottom-right"}},
                                                  {headerText: "Last Name", dataField: "lastName", width: "20%",truncateToFit: true},
                                                  {headerText: "City", dataField: "city", width: "10%",truncateToFit: true},
                                                  {headerText: "Phone Number", dataField: "phoneNumber", width: "10%"},
                                                  {headerText: "Birth Date", dataField: "birthDate", width: "10%"},
                                                  {headerText: "Pet", dataField: "pet", width: "10%",enableFilter:false , sortable:false,
                                                   itemRendererComponent: PetComponentRenderer}
                                                 ];*/
        
        /*const gridColumn: INSGridColumn[] = [{headerText: "Employee ID", dataField: "employeeId", width: "10%",filter:{advancedFilterPopUpPos: "bottom-right"}},
                                             {headerText: "Employee", dataField: "employeeName", width: "30%"},
                                             {headerText: "Employment Type", dataField: "employmentType", width: "30%",truncateToFit: true},
                                             {headerText: "Start Date", dataField: "startDate", width: "20%",truncateToFit: true},
                                             {headerText: "Pet", dataField: "pet", width: "10%",enableFilter:false , sortable:false,
                                              itemRendererComponent: PetComponentRenderer}
                                            ];*/
        
        const gridColumn: INSGridColumn[] = [
                                             {headerText: "Name", dataField: "name", width: "200px",filter:{advancedFilterPopUpPos: "bottom-right"}},
                                             {headerText: "Country", dataField: "country", width: "150px",truncateToFit: true,
                                              itemRendererComponent: CountryRenderer1},
                                             {headerText: "Language", dataField: "language", width: "150px",truncateToFit: true},
                                             {headerText: "Asset", dataField: "asset", width: "180px"},
                                             {headerText: "Proficiency", dataField: "proficiency", width: "120px"},
                                             {headerText: "Phone", dataField: "phoneNumber", width: "130px"},
                                             {headerText: "Pet", dataField: "pet", width: "100px",enableFilter:false , sortable:false,
                                                 itemRendererComponent: PetComponentRenderer}
                                            ];
        
        const editorSetting: INSGridEditorSetting = {clickType:NSGridReact.EDITOR_EDITING_NOCLICK,enableMultipleEdit:true};
        const gridSetting: INSGridReactSettings = {type: NSGridReact.GRID_TYPE_HIERARCHICAL, columnResizable: true,columnSortable:true,
                columnDraggable: false, enableFilter: true, enableAdvancedFilter: true,
               columns: gridColumn, customClass: customClass,rowKeyField:"employeeId",heightOffset:170,
               enableVirtualScroll: true,theme: this.state.theme || this.context.theme};//,childField: "underlings"
        this.state = {
                theme: null,
                gridSetting: gridSetting,
        };
        this.dataGenerator = new DataGenerator();
    }
    
    componentDidMount() 
    {     
        this.getData(); 
    }
    
    public themeChanged(event: any)
    {
        if(this.state)
        {
            this.state.theme = event.details.selectedTheme;    
        }
        if(this.grid)
        {
            this.grid.setTheme(this.state.theme);
        }
    }
    
    render() 
    {
        const buttonStyle: any = {"marginRight": "10px"};
        const gridSetting: INSGridReactSettings = this.state.gridSetting;
        return (
             <div className="box box-ns-red">
              <div className="box-header">
                    <h3 className="box-title">Hierarchical Grid Demo</h3>
                    <div className="pull-right box-tools">
                         <button className="btn btn-ns-grey btn-sm" onClick={this.expandHandler.bind(this)}>
                            <i className='fa fa-expand'></i>&nbsp;&nbsp;&nbsp;Expand
                         </button>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        <button className="btn btn-ns-grey btn-sm" onClick={this.collapseHandler.bind(this)}>
                            <i className='fa fa-compress text-white'></i>&nbsp;&nbsp;&nbsp;Collapse
                        </button>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    </div>
              </div>
              <div className="box-body">
                  <NSGridReact setting={gridSetting} dataSource={[]} ref={(ref) => this.grid = ref} style={{"width": "100%"}}>
                  </NSGridReact>
              </div>
                
             {/*<NSMessageBoxReact setting={modalSetting} ref={(ref) => this.modal = ref} />
             <NSMessageBoxReact setting={modalSetting} ref={(ref) => this.modalEmployee = ref} />*/}
          </div>
               
        )
    }
              
    private getData(): void
    {
       this.grid.dataSource([]);
       /*const self : any = this;
       this.context.ajax.post(this.context.getURL("getHierarchicalData"),{dataLength: 3, tillLevel: 2},null)
       .then(function(response){
           self.context.globalIntercept(response,function(response){
               if(self.grid)
               {
                    self.grid.dataSource(response)
               }
           });
       })
       .catch(err => this.context.globalCatchBlock(err));*/
       
       /*this.context.ajax.jsonp("https://nscomponents18.github.io/hierarchicalData.json",null,{callback: "processJSONPResponse"})
       .then((response) => {
               if(this.grid)
               {
                    console.log(response);
                    this.grid.dataSource(response);
               }
       })
       .catch(err => this.context.globalCatchBlock(err));*/
       
       let arrData: any[] = this.dataGenerator.getHierarchicalData(1000);
       if(this.grid)
       {
           this.grid.dataSource(arrData);
       }
    }

    private expandHandler(event: any): void
    {
        this.grid.expandAll();
    }

    private collapseHandler(event: any): void
    {
        this.grid.collapseAll();
    }
   
}

