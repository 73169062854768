import * as React from 'react';

export class LoadingSpinner extends React.Component<any, any> 
{
    private element: any;
    //when routes are loaded second time this flag will be used for showing icon
    private waitingElementLoad: boolean = false; 
    
    constructor(public props: any, public state: any) 
    {
        super(props, state);
    }
    
    public componentDidMount() 
    {
        if(this.waitingElementLoad)
        {
            this.show();
            this.waitingElementLoad = false;
        }
    }
    
    componentDidUpdate()
    {
        if(this.waitingElementLoad)
        {
            this.show();
            this.waitingElementLoad = false;
        }
    }
    
    public render() 
    {
        return <div className="spinner-container" ref={(ref) => this.element = ref}>
                    <div className="spinner">
                        <i className="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;&nbsp;<b className="modal-title">Please Wait !</b>
                    </div>
               </div>;
    }

    show() : void
    {
        this.__showHide(true);
    }
    
    hide() : void
    {
        this.__showHide(false);
        this.waitingElementLoad = false;
    }

    private __showHide(isShow:Boolean):void
    {
        if(this.element)
        {
            this.element.style.display = isShow ? "block" : "none";
        }
        else
        {
            this.waitingElementLoad = true;
        }
    }
    
    public getElement(): any
    {
        return this.element;
    };
    
    
    
}