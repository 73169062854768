import React, {forwardRef, useEffect, useImperativeHandle, useRef, memo, useState} from 'react';

export const TextBoxEditor = memo(forwardRef((props, ref) => {
    const [value, setValue] = useState("");
    const refInput = useRef(null);

    useEffect(() => {
        refInput.current.focus();
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            init(data:any) {
                setValue(data.defaultValue);
            },

            elementAdded() {
                console.log("elementAdded")
            },
            getValue() {
                return value;
            }
        };
    });

    return (
        <input type="text"
               ref={refInput}
               value={value}
               onChange={event => setValue(event.target.value)}
               style={{width: "100%"}}
        />
    );
}));