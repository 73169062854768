import * as React from "react";

import {BaseComponent} from '../base/baseComponent';
import {NSDatePickerReact, INSDatePickerReactSetting, INSDatePickerCustomClass} from 'nscomponentsreact';

export class Panel extends BaseComponent
{

    constructor(public props: any, public state: any) 
    {        
        super(props,state);
        this.state = {
             theme: null,
        };
    
    }
    
    componentDidMount() 
    {     
    }
    
    public themeChanged(event: any)
    {
        if(this.state)
        {
            this.state.theme = event.details.selectedTheme;    
        }
    }
    
    render() 
    {
            return (
             <div className="box box-ns-red">
                  <div className="box-header">
                        <h3 className="box-title">Panel Demo</h3>
                  </div>
                  <div className="box-body">
                        
                  </div>
             </div>
                  
           );
               
    }

}