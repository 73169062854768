import * as React from "react";

import {BaseComponent} from '../base/baseComponent';
import {NSCalendarReact, INSCalendarReactSetting} from 'nscomponentsreact';

export class Calendar extends BaseComponent
{
    private calSimple: NSCalendarReact;
    private calMinMax: NSCalendarReact;
    private calDisabled: NSCalendarReact;
    private calFooter: NSCalendarReact;

    constructor(public props: any, public state: any) 
    {        
        super(props,state);
        this.state = {
             theme: null,
        };
    
    }
    
    componentDidMount() 
    {     
    }
    
    public themeChanged(event: any)
    {
        if(this.state)
        {
            this.state.theme = event.details.selectedTheme;    
        }
    }
    
    render() 
    {
        let cellStyle: any = {display: "inline-block",width:"50%"};
        let rowStyle: any = {marginLeft: "100px"};
        return (
             <div className="box box-ns-red">
                  <div className="box-header">
                        <h3 className="box-title">Calendar Demo</h3>
                  </div>
                  <div className="box-body">
                        <div className="row" style={rowStyle}>
                            <div style={cellStyle}>
                                <p> Basic Calendar</p>
                                <NSCalendarReact ref={(ref) => this.calSimple = ref} onDateSelected={this.dateSelected.bind(this,"simple","divSimpleCalSelectedDate")}>
                                </NSCalendarReact>
                                <div id="divSimpleCalSelectedDate" style={{height:"60px"}}>
                                &nbsp;
                                </div>
                            </div>
                            <div style={cellStyle}>
                                <p>Calendar with Min and Max date</p>
                                <NSCalendarReact ref={(ref) => this.calMinMax = ref} onDateSelected={this.dateSelected.bind(this,"minmax","divCalMinMaxSelectedDate")}>
                                </NSCalendarReact>
                                <div id="divCalMinMaxSelectedDate" style={{height:"60px"}}>
                                &nbsp;
                                </div>
                            </div>
                        </div>
                        <div className="row" style={rowStyle}>
                            <div style={cellStyle}>
                                <p>Calendar with Disabled Date (date less than today is disabled)</p>
                                <NSCalendarReact  ref={(ref) => this.calDisabled = ref} onDateSelected={this.dateSelected.bind(this,"disabled","divCalDisabledSelectedDate")}>
                                </NSCalendarReact>
                                <div id="divCalDisabledSelectedDate" style={{height:"60px"}}>
                                &nbsp;
                                </div>
                            </div>
                            <div style={cellStyle}>
                                <p>Calendar With Footer</p>
                                <NSCalendarReact  ref={(ref) => this.calFooter = ref} onDateSelected={this.dateSelected.bind(this,"footer","divFooterCalSelectedDate")}>
                                </NSCalendarReact>
                                <div id="divFooterCalSelectedDate" style={{height:"60px"}}>
                                &nbsp;
                                </div>
                            </div>
                        </div>
                  </div>
             </div>
               
        )
    }
    
    dateSelected(type: string,destID: any,event: any)
    {
        let div: any = document.querySelector("#" + destID);
        if(div)
        {
            div.innerHTML = event.detail;
        }
        /*switch(type)
        {
            case "simple":
                break;
        }*/
    }

}

