import * as React from "react";

import {BaseComponent} from '../base/baseComponent';
import {NSDashboardReact, INSDashboardReactSettings, INSDashboardPanelSetting, INSPanelToolBarDetails} from 'nscomponentsreact';
import { AnimalComponent } from './components/animalComponent';

export class Dashboard extends BaseComponent
{
    private dashboard: NSDashboardReact;

    constructor(public props: any, public state: any) 
    {        
        super(props,state);
        this.state = {
             theme: null,
             setting: this.getSetting(),
        };
    
    }
    
    componentDidMount() 
    {     
    }
    
    public themeChanged(event: any)
    {
        if(this.state)
        {
            this.state.theme = event.details.selectedTheme;    
        }
    }
    
    render() 
    {
        const setting: INSDashboardReactSettings = this.state.setting;
        return (
         <div className="box box-ns-red">
              <div className="box-header">
                    <h3 className="box-title">Dashboard Demo</h3>
              </div>
              <div className="box-body">
                    <NSDashboardReact setting={setting} ref={(ref) => this.dashboard = ref} className="dashboardDemo" style={{"width": "100%",height:"100%"}}>
                    </NSDashboardReact>
              </div>
         </div>
              
       );
               
    }

    private getSetting(): INSDashboardReactSettings
    {
        const toolBarDetails: INSPanelToolBarDetails = {
                minimize:{iconHTML:null,title:"Minimize"},
                maximize:{iconHTML:null,title:"Maximize"},
                expand:{iconHTML:null,title:"Expand"},
                collapse:{iconHTML:null,title:"Collapse"},
                fullScreen:{iconHTML:"<i class='icon-fullscreen icon-1' style='pointer-events: none;'></i>",title:"Click here for Full Screen"},
                restore:{iconHTML:"<i class='fa fa-window-restore fa-1'></i>",title:"Click here to Restore"},
                close:{iconHTML:null,title:"Close"}
        };
        let setting: INSDashboardPanelSetting = {title:"Demo",titleHtml:"<i class='icon-move icon-1'></i>&nbsp;&nbsp;Demo",minWidth:300,minHeight:300,enablePopUp:false,enableModal:false,
                       enableCollapse:true,enableMinimization:true,enableFullScreen:true,enableDrag:false, 
                       enableResize:true,enableTitleDblClick:false,enableMoveOnClick:false,
                       customClass:{container:"panel",titleBar:"panelTitleBar",titleBarContent:"panelTitleBarContent",iconConatiner:null,icon:null,body:null},
                       bodyComponent: AnimalComponent};
        const panelCount: number = 6;
        let arrSetting: INSDashboardPanelSetting[] = [];
        let objAnimal: any = {"0":{name: "dog"},"1":{name: "cat"},"2":{name: "goat"},"3":{name: "sheep"},"4":{name: "horse"},"5":{name: "none"}}
        for(let count: number = 0;count < panelCount;count++)
        {
            arrSetting[count] = JSON.parse(JSON.stringify(setting));
            arrSetting[count].title = "Demo" + (count + 1);
            arrSetting[count].titleHtml = "<i class='icon-move icon-1'></i>&nbsp;&nbsp;Demo&nbsp;" + (count + 1);
            arrSetting[count].contentComponent = AnimalComponent;
            arrSetting[count].contentComponentData = objAnimal[count];
        }
        let dashBoardSetting: INSDashboardReactSettings = {panelDragClass:"drag",panelCount:panelCount,panelPerRow:3,arrPanelSetting:arrSetting};
        return dashBoardSetting;
    }

}