import * as React from "react";
import { INSGridRendererComponent, INSGridEditorCustomComponentSetting } from 'nscomponentsreact';
import { NSMessageBoxReact, INSMessageBoxReactSettings } from 'nscomponentsreact';
import { INSMessageBoxCustomSetting } from 'nscomponentsreact';
import {BaseComponent} from '../../base/baseComponent';

export class ProficiencyRenderer extends BaseComponent implements INSGridRendererComponent
{
    private element: any;

    constructor(public props: any, public state: any) 
    {
        super(props,state);
        this.state = {item: null};
    }
    
    componentDidMount() 
    {
        //console.log(this.context);
    }
    
    setData(data: any,dataField: string,index: number,colIndex: number,row: any): void
    {
        let color: string = "";
        let value: number = data["proficiency"];
        if (value < 20) 
        {
            color = "#f55d51";
        } 
        else if (value < 60) 
        {
            color = "#ffb300";
        } 
        else 
        {
            color = "#82d249";
        }
        this.setState({item: data,color: color,value: data["proficiency"]});
    };
    
    getElement(): any
    {
        return this.element;
    };
        
   render() 
   {
       const style = {width: "100%"};
       const barStyle = {width: this.state.value + "%",backgroundColor: this.state.color};
       return (
               <div className="proficiency" ref={(ref) => this.element = ref} style={style}>
                   <div className="proficiencyWrapper">
                       <div className="proficiencyBar" style={barStyle}>
                       </div>
                   </div>
                   <div className="proficiencyValue">{this.state.value}%</div>
              </div>
       )
   }

   
}
