import * as React from "react";
import { INSGridRendererComponent, INSGridEditorCustomComponentSetting } from 'nscomponentsreact';
import { NSMessageBoxReact, INSMessageBoxReactSettings } from 'nscomponentsreact';
import { INSMessageBoxCustomSetting } from 'nscomponentsreact';
import {BaseComponent} from '../../base/baseComponent';

export class RealTimeRenderer extends BaseComponent implements INSGridRendererComponent
{
    private element: any;
    private lastValue: number = 0;
    private refreshCount: number = 0;

    constructor(public props: any, public state: any) 
    {
        super(props,state);
        this.state = {item: null};
    }
    
    componentDidMount() 
    {
        //console.log(this.context);
    }
    
    setData(data: any,dataField: string,index: number,colIndex: number,row: any): void
    {
        console.log(index);
        let color: string = "";
        let value: number = data[dataField];
        let delta: number = null;
        if (value != this.lastValue) 
        {
            if (typeof value === 'number' && typeof data.prevAsset === 'number') 
            {
                delta = value - data.prevAsset;
            }
            this.lastValue = value;
            this.setState({item: data,value: value,delta: Math.abs(delta),deltaUp: (delta >= 0)});
            this.removeDelta();
        }
    };
    
    getElement(): any
    {
        return this.element;
    };
        
   render() 
   {
       const style = {width: "100%"};
       const classIcon: string = "fa " + ((this.state.delta == null) ? "" : (this.state.deltaUp ? "fa-arrow-up realTimeCellDeltaUp" : "fa-arrow-down realTimeCellDeltaDown"));
       const classRealTimeDelta: string = "realTimeCellDelta " + (this.state.deltaUp ? "realTimeCellDeltaUp" : "realTimeCellDeltaDown");
       const classRealTimeValue: string = "realTimeCellValue " + ((this.state.delta == null) ? "" : "realTimeCellValueHighlight");
       return (
               <div className="realTimeCell" ref={(ref) => this.element = ref} style={style}>
                   <i className={classIcon}></i>&nbsp;
                   <span className={classRealTimeDelta}>
                       {this.state.delta}
                   </span>
                   <span className={classRealTimeValue}>
                       {this.state.value}
                   </span>
              </div>
       )
   }

   private removeDelta(): void
   {
       this.refreshCount++;
       let tempRefreshCount: number = this.refreshCount;
       let self = this;
       window.setTimeout(() => {
           if (tempRefreshCount === self.refreshCount) {
               self.setState({delta: null})
           }
       }, 2000);
   }

   
}
