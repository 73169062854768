import React from 'react';
import PropTypes from 'prop-types';
import './textAnimation.css';

class TextAnimation extends React.Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  
  private arrSpan: any[] = [];
  private totalCharLength: number = 0;
  private totalAnimationCompleted: number = 0;
  private transitionEndEventName: string;
  
  constructor(public props: any, public state: any) {
    super(props,state);
    this.getDelays = this.getDelays.bind(this);
    this.renderToSpan = this.renderToSpan.bind(this);
    this.transitionEndEventName = this.getTransitionEndEventName();
    if(this.props.breakChar && this.props.breakChar.length > 1)
    {
        console.warn("breakChar property should be a character.Since it is more than 1 character it will not be used.");
    }
  }

  shouldComponentUpdate(nextProps) {
    const showChanged = this.props.show !== nextProps.show;
    const textChanged = this.props.text !== nextProps.text;
    const childrenChanged = this.props.children !== nextProps.children;
    return showChanged || textChanged || childrenChanged;
  }

  getDelays(length) {
    const { threshold, delayMin, delayMax } = this.props;

    // generate random numbers and then convert to delays
    const randoms = () => this.getRandoms(length, threshold);
    const toDelay = num => this.randomToDelay(num, delayMin, delayMax);

    return randoms().map(toDelay);
  }

  renderToSpan({ character , delay }, index) {
    const { show, transitionTime, timingFunction, charClassName, breakChar } = this.props;
    const style = {
      opacity: show ? '1' : '0',
      transition: `opacity ${transitionTime}ms`,
      transitionDelay: `${delay}ms`,
      transitionTimingFunction: timingFunction,
    };
    if(character == breakChar)
    {
        return <br/>
    }
    else
    {
        let compSpan: any = null;
        setTimeout(() => {
            if(this.arrSpan[index])
            {
                this.arrSpan[index].addEventListener(this.transitionEndEventName, this.transitionEnd.bind(this,index));
            }
            else
            {
                console.log("Span for " + index + " not created");
            }
        }, 500);
        return <span className={charClassName} ref={(ref) => this.arrSpan[index] = ref} key={index} style={style}>{character}</span>;
    }
  }
  
  transitionEnd(index: number, event: any)
  {
      this.totalAnimationCompleted++;
      //console.log(this.totalAnimationCompleted + "==" + this.totalCharLength);
      if((this.totalAnimationCompleted + 1) == this.totalCharLength)
      {
          if(this.props.animationEndCallback)
          {
              this.props.animationEndCallback();
          }
      }
  }

  renderSpans(text) {
    const textArray: any[] = text.split('');

    const delays = this.getDelays(textArray.length);
    this.totalCharLength = textArray.length;
    const combineWithDelays =
      (character, index) => ({ character , delay: delays[index] });

    return textArray
      .map(combineWithDelays)
      .map(this.renderToSpan);
  }

  render() {
    const { style, className } = this.props;
    const text = this.props.text || this.props.children;

    return (
      <div style={style} className={className}>
        {this.renderSpans(text)}
      </div>
    );
  }
  
  getRandoms(length, threshold){
      const tooClose = (a, b) => Math.abs(a - b) < threshold;

      const result = [];
      let random;

      for (let i = 0; i < length; i += 1) {
        random = Math.random();
        if (i !== 0) {
          const prev = result[i - 1];
          while (tooClose(random, prev)) {
            random = Math.random();
          }
        }
        result.push(random);
      }
      return result;
    };

    randomToDelay(random, min, max) {
      const float = random * (max - min);
      return parseInt(float.toString(), 10) + min;
    };
    
    getTransitionEndEventName() {
        let transitions = {
            "transition"      : "transitionend",
            "OTransition"     : "oTransitionEnd",
            "MozTransition"   : "transitionend",
            "WebkitTransition": "webkitTransitionEnd"
         }
        let bodyStyle = document.body.style;
        for(let transition in transitions) {
            if(bodyStyle[transition] != undefined) {
                return transitions[transition];
            } 
        }
    };
}

TextAnimation.propTypes = {
  text: PropTypes.string,
  show: PropTypes.bool,
  transitionTime: PropTypes.number,
  timingFunction: PropTypes.string,
  delayMin: PropTypes.number,
  delayMax: PropTypes.number,
  threshold: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  charClassName: PropTypes.string,
  children: PropTypes.string,
  animationEndCallback: PropTypes.func,
  breakChar: PropTypes.string
};

TextAnimation.defaultProps = {
  transitionTime: 1300,
  timingFunction: 'linear',
  delayMin: 200,
  delayMax: 1200,
  threshold: 0.2,
  text: '',
  className: '',
  charClassName: '',
  style: {},
  show: false,
  children: undefined,
  animationEndCallback: undefined,
  breakChar: undefined,
};

export default TextAnimation;