  import React from 'react';
//import { NSAjax } from 'nsajax';

import { NSAjaxReact, NSAjax } from 'nscomponentsreact';
import { NSMessageBoxReact, INSMessageBoxReactSettings, INSPanelSetting } from 'nscomponentsreact';

import { Authenticate } from "./routes/authenticate";
import { Header } from "./header";
import { Footer } from "./footer";
import { LoadingSpinner } from '../lib/loadingSpinner/loadingSpinner';

import {BaseComponent} from '../modules/base/baseComponent';
import {GlobalContext} from './globalContext';
import {ErrorBoundary} from './errorBoundary';
import { PopUpService } from '../modules/helper/popUpService';
import { NSReactEvent } from '../modules/helper/nsReactEvent';

import TextAnimation from './animation/textAnimation';


class App extends BaseComponent
{
    private compLoader: LoadingSpinner = null;

    private modal: NSMessageBoxReact;
    private appContainer: HTMLDivElement;
    private appHeader:Header;
    private authenticate: Authenticate;
    private resizeHandlerRef: any;

    constructor(public props: any, public state: any) 
    {
        super(props,state);
        let ajax: NSAjaxReact = new NSAjaxReact({dataType: "JSON"});
        const loc: any = window.location;
        let route: string = null;
        if(loc.hash && loc.hash.length > 0)
        {
            const hash:string = loc.hash;
            route = hash.replace("#!/","");
        }
        const isProd: boolean = (process.env.NODE_ENV == "production");
        this.state = 
        {
                ajax: ajax,
                isProd: isProd,
                url: isProd ? "api/" : "http://localhost:8080/reactDemo/api/",
                baseName: isProd ? "/NSComponent" : "",
                userObj: null,
                isLoggedIn: false,
                isAuthorized: false,
                setProp: this.setContextState.bind(this),
                getURL: this.getURL.bind(this),
                globalCatchBlock: this.globalCatchBlock.bind(this),
                globalIntercept: this.globalIntercept.bind(this),
                modal: null,
                popUpService: null,
                loader: null,
                theme: null,
                route: route,
                enableAnimation: true,
                animate: false,
                animationEnd: false,
                initialzed: false,
        };
        this.getLatestRepositoriesFromGithub();
        this.getLatestUsersFromGithub();
    }

    getLatestRepositoriesFromGithub = () => {
        const params = {q: "created:>" + this.getDate(),sort:"stars",order:"desc",per_page: "5",page:"1"};
        const url = "https://api.github.com/search/repositories?" + this.objectToQueryString(params);
        fetch(url)
        .then (res => res.json())
        .then (data => {
            console.log(data);
        })
    }

    getLatestUsersFromGithub = () => {
        const params = {q: "created:>=" + this.getLastYearDate(),sort:"followers",order:"desc",per_page: "5",page:"1"};
        const url = "https://api.github.com/search/users?" + this.objectToQueryString(params);
        fetch(url)
        .then (res => res.json())
        .then (data => {
            console.log(data);
        })
    }

    objectToQueryString = (obj) => {
        return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
    }

    getDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth().toString().padStart(2, '0');
        return year + "-" + month + "-" + "01";
    }

    getLastYearDate = () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 1);
        const year = date.getFullYear();
        const month = date.getMonth().toString().padStart(2, '0');
        return year + "-" + month + "-" + date.getDate();
    }

    
    
    render()
    {
        //const item: any = {ajax: ajax,url: this.url,userObj: null};
        //const useAuthState = React.useReducer(globalReducer, item);
        const containerStyle: any = {"height": window.innerHeight - 20};
        const modalSetting: INSMessageBoxReactSettings = {enableModalCloseOnEscape: true,enableCloseIconVisibility: true};
        const { enableAnimation, animate, animationEnd } = this.state;
        const bgStyles: any = {
                background: 'linear-gradient(135deg, #723362, #9d223c)',
                padding: '36px',
                paddingTop: '48px',
                minHeight: "100vh",
              };
              const textStyles: any = {
                color: 'white',
                fontSize: '24px',
                lineHeight: '36px',
                fontFamily: 'sans-serif',
                textAlign: 'center',
                letterSpacing: '1em',
                paddingLeft: '1em', // to compensate for letter spacing
                paddingTop: "calc(25vh - 1px)",
              };
        return (
                <GlobalContext.Provider value={this.state}>
                    <ErrorBoundary>
                      
                      <div id="divAppContainer" ref={(ref) => this.appContainer = ref} style={containerStyle}>
                      {(enableAnimation && !animationEnd) && ( 
                              <div style={bgStyles}>
                              <div style={textStyles}>
                                  <TextAnimation show={this.state.animate} timingFunction="ease-in-out" charClassName="textAnimation" breakChar="|" animationEndCallback={this.endAnimation.bind(this)}>
                                      WELCOME TO|WORLD OF|NSCOMPONENT
                                  </TextAnimation>
                              </div>
                            </div>
                       )}
                      
                          {/*<div [hidden]="!unauthorized">
                                <div class="box box-danger box-border screen-center-box">
                                    <div class="box-header with-border">
                                        <i class="fa fa-ban text-ns-red"></i>
                                        <h3 class="box-title">Unauthorized</h3>
                                    </div>
                                    <!-- /.box-header -->
                                    <div class="box-body">
                                        You are not authorized to access this application.
                                    </div><!-- /.box-body -->
                                </div>
                          </div>*/}
                          {/*<div [hidden]="!showSpoofLogin">
                                <nm-spooflogin (loginUser) = "spoofLoginUser($event)">
                                </nm-spooflogin>
                          </div>*/}
                          {(!enableAnimation || animationEnd) && ( 
                             <div id="divPageContainer" className="nmPageContainer">
                                  <Header ref={(ref) => this.appHeader = ref} toggleNavigation={this.toggleNavigation.bind(this)}>
                                  </Header>
                                  <Authenticate ref={(ref) => this.authenticate = ref}>
                                  </Authenticate>
                                  <Footer>
                                  </Footer>
                              </div>
                          )}
                          <LoadingSpinner id="divLoader" ref={(ref) => this.compLoader = ref}></LoadingSpinner>
                          <NSMessageBoxReact setting={modalSetting} ref={(ref) => this.modal = ref} />
                      </div>
                    </ErrorBoundary>
                </GlobalContext.Provider>
        );
    }

    
    componentDidMount() 
    {
        if(this.state.animationEnd)
        {
            if(!this.state.initialzed)
            {
                this.initialize();
            }
        }
        else
        {
            this.compLoader.hide();
            setTimeout(() => {
                this.setState({ animate: true });
            }, 500);
        }
    }
    
    componentDidUpdate(prevProps: any, prevState: any, snapshot: any)
    {
        if(this.state.animationEnd)
        {
            if(!this.state.initialzed)
            {
                this.initialize();
            }
        }
    }
    
    componentWillUnmount() 
    {
        if(this.resizeHandlerRef)
        {
            window.removeEventListener('resize', this.resizeHandlerRef);
            this.resizeHandlerRef = null;
        }
    }
    
    endAnimation()
    {
        console.log("In endAnimation");
        setTimeout(() => {
            this.setState({ animate: false,animationEnd: true });
        }, 900);
    }

    public themeChanged(event: any)
    {
        let body: any = document.body;
        const selectedTheme: string = event.details.selectedTheme;
        const oldTheme: string = event.details.oldTheme;
        if(oldTheme)
        {
            body.classList.remove("nmPageBody" + oldTheme);
        }
        body.classList.add("nmPageBody" + selectedTheme);
        this.state.theme = selectedTheme;
    }

    public toggleNavigation()
    {
        this.authenticate.toggleNavigation();
    }
    
    private initialize()
    {
        this.getUserInfo();
        this.state.initialzed = true;
        let self = this;
        //self.compLoader.hide();
        window.addEventListener(NSAjax.REQUEST_STARTED,function(){
            //self.compLoader.show();
            self.state.loader.show();
        });
        window.addEventListener(NSAjax.ALL_REQUEST_COMPLETED,function(){
           self.compLoader.hide();
           
        });
        this.setState({modal: this.modal,popUpService: new PopUpService(this.modal),loader: this.compLoader});
      
        if(this.resizeHandlerRef)
        {
            window.removeEventListener('resize', this.resizeHandlerRef);
            this.resizeHandlerRef = null;
        }
        this.resizeHandlerRef = this.debounce(this.resizeHandler.bind(this), 800);
        window.addEventListener('resize', this.resizeHandlerRef);
    }
    
    private resizeHandler(event: any)
    {
        if(this.appContainer)
        {
            this.appContainer.style.height = window.innerHeight + "px";
            NSReactEvent.getInstance().dispatch("screenresized",{width: window.innerWidth,hieght:window.innerHeight});
        }
    }
    
    private debounce(fn, ms) 
    {
        let timer
        return _ => {
          clearTimeout(timer)
          timer = setTimeout(_ => {
            timer = null
            fn.apply(this)
          }, ms)
        };
     };
    
    private getURL(methodName:string): string
    {
      return (this.state.url + methodName);
    }
    
    private globalIntercept(response: any,callback: any)
    {
       if(response && response["STATUS"] && response["STATUS"] === "FAILURE")
       {
           this.globalCatchBlock(response["MESSAGE"]);
       }
       else if(callback)
       {
           callback(response);
       }
    }
    
    private globalCatchBlock(error: any)
    {
        if(error)
        {
            this.state.popUpService.showFailureAlertPopup(error);
        }
    }
    
    private setContextState(item: any): void
    {
        this.setState(item);
    }

    private getUserInfo(): void
    {
        /*const self : any = this;
        self.state.ajax.post(self.state.getURL("getLoginInfo"),null)
        .then(function(response: any)
         {
            self.state.globalIntercept(response,function(response){
                if(response)
                { 
                    console.log(response) ; 
                    let item: any = {userObj: response,isLoggedIn: true,isAuthorized: true};
                    self.state.setProp(item);
                    self.appHeader.setHeader(response) ;
                    self.authenticate.initialize();
                }
                else
                {
                    self.state.popUpService.showFailureAlertPopup("Employee Details could not be fetched.");
                }
            });
         })
        .catch(err => this.state.globalCatchBlock(err));
        this.compLoader.hide();*/
        setTimeout(_ => {
            if(!this.state.userObj)
            {
                this.compLoader.hide();
                let response: any = {employeeName: "NSCompoment", environment: "PROD", userRole: "Employee"};
                let item: any = {userObj: response,isLoggedIn: true,isAuthorized: true};
                this.state.setProp(item);
                this.appHeader.setHeader(response) ;
                this.authenticate.initialize();
            }
        }, 500);
        
    }

    
     
     
      
    
    
}

export default App;