import React, {useState,useContext,useEffect,useRef } from 'react';

import { NSTextBoxReact, INSTextBoxReactSetting, INSGridColumn } from 'nscomponentsreact';
import { fetchCountriesWithText } from '../../util/countryApi';



export const AutoComplete = (props: any) => {
    let textBoxRef = useRef<NSTextBoxReact>(null);
    let selectedTextBoxItemRef = React.useRef<any>(null);
    const defaultState: any = {
        textBoxSetting: null,
    };

    const [state, setState] = useState(defaultState);

    const LABEL_FIELD = 'name';
    const SEARCH_FIELD = ['name','code'];

    useEffect(() => {
        setTextBoxSetting();
    }, []);

    const setTextBoxSetting = () => {
        if(!state.textBoxSetting) {
            const objState = {...state};
            let column: INSGridColumn[] =  [{headerText: 'Country',dataField: 'name', width: '140px'},
                                            {headerText: 'Code',code: 'code', width: '140px'}];
            let dropDownSetting: any = {columns:column,enableVirtualScroll:true,enableFilter:true,enableAdvancedFilter:true,enablePagination:false};
            let filterSetting: any = {caseSensitive:false,multiline:false,matchType:NSTextBoxReact.FILTER_TYPE_CONTAINS};
            objState.textBoxSetting = {enableServerWithSmartSearch:true,placeholder:"Search Country...",type:"autocomplete",dropDownType:NSTextBoxReact.DROPDOWN_TYPE_GRID,
                                        listWidth:700,required:true,labelField:LABEL_FIELD,minSearchStartChars:3,
                                        enableMultipleSelection:false,enableKeyboardNavigation:true,
                                        stopHoveringField:"stopOver",dropDownSetting:dropDownSetting,filterSetting:filterSetting,enableHighlighting:true,
                                        isGridOrFilter:true,arrGridSearchField: SEARCH_FIELD,
                                        serverSearchCallback:fetchCountryList,textBoxRendererCallback:textBoxRenderer};
            setState(objState);
        }
    };

    const fetchCountryList = (searchText: string) => {
        console.log("In fetchCountryList, ",searchText);
        fetchCountriesWithText(searchText).then(data => {
            if(textBoxRef.current) {
                textBoxRef.current.dataSource(data);
             }
        })
        .catch((err:any)=> console.log(err));
    };

    const textBoxRenderer = (item:any,labelField:string) : string => {
        //const objState = {...state};
        let retVal = "";
        if(item) {
            selectedTextBoxItemRef.current = item ;                       
            if(textBoxRef.current) {
                textBoxRef.current.dataSource([]);
            }
            retVal = item[labelField];
        }
        else {
            selectedTextBoxItemRef.current = null; 
        }
        //setState(objState);
        return retVal;
    };


    return (
            <>
                {state.textBoxSetting && (<div className="box box-danger box-border screen-center-box"  >
                    <div className="box-header with-border">
                        <i className="fa fa-hand-o-right"></i>
                        <h3 className="box-title">Demo</h3>
                    </div> 
                    <div className="box-body">
                        <div className="text-center">
                            <div className="form-group form-group-sm" style={{paddingLeft:"20px",paddingRight:"20px"}} >
                                <NSTextBoxReact setting={state.textBoxSetting} ref={textBoxRef}>
                                </NSTextBoxReact>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </>
        );
};
