import React from 'react';

import { NSReactEvent } from '../modules/helper/nsReactEvent';
import {BaseComponent} from '../modules/base/baseComponent';
import {Title} from './title';


export class Header extends BaseComponent
{
    private arrTheme = [{value:'White',name:'White'},
                        {value:'Black',name:'Black' }];
    constructor(public props: any, public state: any) 
    {
        super(props,state);
        this.state={selectedTheme:"White"}
    }
    
    componentDidMount() 
    {
        NSReactEvent.getInstance().dispatch("themeChanged",{selectedTheme: this.state.selectedTheme,oldTheme: null});
    }
    
    render()
    {
        const name:string = this.state.name;
        const env:string =  this.state.env;
        const access:string =  this.state.access;
        const isAuthorized = this.context.isAuthorized;
        const toggleBarStyle: any = {display: isAuthorized ? "" : "none"};
        return (
               <header className="nmPageHeader" style={{width:"100%"}}>
                  <a className="nmLogo">
                    <span className="nmLogoTextMini"><b>NS</b></span>
                    <span className="nmLogoText"><b>NSComponent</b></span>
                  </a>
                  <nav className="nmPageHeaderContent" role="navigation">
                        {/*<a href="#" id="compMainToggleBar" style={toggleBarStyle} className="nmSidebarToggler" role="button" onClick={this.toggleSideBar.bind(this)}>
                          <i className="fa fa-bars"></i>
                        </a> */}
                        <div className="nmHeaderRight" >
                            <div className="nmAppName">
                              <h1>Welcome to NSCOMPONENT</h1>
                              {/*<Title title="NSCOMPONENT"></Title>*/}
                            </div>
                            <div className="nmAppDetail">
                              <div style={{"display":"inline-block"}}>
                                <select id="cmbTheme" className="select" value={this.state.selectedTheme} onChange={this.handleThemeChange.bind(this)}>
                                    <option key="-1" value="-1">Select Theme</option>
                                    {this.arrTheme.map((item, key) => {
                                        return <option key={key} value={item.value}>{item.name}</option>;
                                    })}
                                </select>
                              </div>
                              {/*<div className="welcomeMessage">
                                  Welcome: <b>{name} </b>&nbsp;&nbsp;|&nbsp;&nbsp;
                                  <span className="envInfo">Environment: <b >{env}</b></span>
                                 <br/>
                                 <span>Access: <b>{access}</b></span>&nbsp;&nbsp;&nbsp;
                              </div>*/}
                              <div className="welcomeMessage">
                                  Select Theme:&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>
                         </div>
                  </nav>
             </header>                           
        );
    }

    
    public setHeader(response:any) : void
    {
        this.setState( {name:response.employeeName,env:response.environment,access:response.userRole})
    }

    private toggleSideBar(): void
    {
        this.props.toggleNavigation();
    }

    private handleThemeChange(event: any): void
    {
        const selectedTheme: string = event.target.value;
        if(selectedTheme && selectedTheme != "-1")
        {
            NSReactEvent.getInstance().dispatch("themeChanged",{selectedTheme: selectedTheme,oldTheme: this.state.selectedTheme});
        }
        this.setState({selectedTheme: event.target.value})
    }
 
    
  
}