import * as React from "react";

import {BaseComponent} from '../base/baseComponent';
import { NSAjaxReact } from 'nscomponentsreact';
import { NSGridReact, INSGridReactSettings } from 'nscomponentsreact';
import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting } from 'nscomponentsreact';
import {CommonUtil} from '../../util/commonUtil';


export class GroupingGridDemo extends BaseComponent
{
    private commonUtil: CommonUtil;
    private grid: NSGridReact;
    private arrGroupBy = [{value:'currency',name:'Currency'},
                          {value:'name',name:'Country'},
                          {value:'phone',name:'Phone Number'},
                         ];
    constructor(public props: any, public state: any) 
    {        
        super(props,state);
        this.commonUtil = new CommonUtil();
        const customClass: INSGridCustomClassSetting = {headerCell:"columnClass",nonFirstBodyColumn:"columnClass"};
        
        /*const gridColumn: INSGridColumn[] = [
                                              {headerText: "Pet", dataField: "pet", width: "10%",enableFilter:false,sortable:false},
                                              {headerText: "First Name", dataField: "firstName", width: "15%",filter:{advancedFilterPopUpPos: "bottom-right"}},
                                              {headerText: "Last Name", dataField: "lastName", width: "15%",truncateToFit: true},
                                              {headerText: "City", dataField: "city", width: "15%",truncateToFit: true},
                                              {headerText: "Phone Number", dataField: "phoneNumber", width: "15%"},
                                              {headerText: "Birth Date", dataField: "birthDate", width: "20%"},
                                            ];*/
        
        const gridColumn: INSGridColumn[] = [{headerText: "Currency", dataField: "currency", width: "10%",enableFilter:false , sortable:false},
                                             {headerText: "Country", dataField: "name", width: "30%",filter:{advancedFilterPopUpPos: "bottom-right"}},
                                             {headerText: "Native", dataField: "native", width: "20%",truncateToFit: true},
                                             {headerText: "Capital", dataField: "capital", width: "30%",truncateToFit: true},
                                             {headerText: "Phone Number", dataField: "phone", width: "15%"},
                                             {headerText: "Continent", dataField: "continent.name", width: "20%"},
                                            ];
        
        const editorSetting: INSGridEditorSetting = {clickType:NSGridReact.EDITOR_EDITING_NOCLICK,enableMultipleEdit:true};
        const gridSetting: INSGridReactSettings = {type: NSGridReact.GRID_TYPE_GROUP,groupByField:"currency",columnResizable: true,columnSortable:true,
                columnDraggable: false, enableFilter: true, enableAdvancedFilter: true,
               columns: gridColumn, customClass: customClass,rowKeyField:"code",heightOffset:170,
               enableVirtualScroll: true,theme: this.state.theme || this.context.theme};
        this.state = {
                theme: null,
                gridSetting: gridSetting,
                groupBy: "currency"
        };
    
    }
    
    componentDidMount() 
    {     
        this.getData(); 
    }
    
    public themeChanged(event: any)
    {
        if(this.state)
        {
            this.state.theme = event.details.selectedTheme;    
        }
        if(this.grid)
        {
            this.grid.setTheme(this.state.theme);
        }
    }
    
    render() 
    {
        const buttonStyle: any = {"marginRight": "10px"};
        const gridSetting: INSGridReactSettings = this.state.gridSetting;
        return (
             <div className="box box-ns-red">
              <div className="box-header">
                    <h3 className="box-title">Grouping Grid Demo</h3>
                    <div className="pull-right box-tools">
                         <select id="cmbGroupBy" className="select" value={this.state.groupBy} onChange={this.handleGroupByChange.bind(this)}>
                                    <option key="-1" value="-1">Select GroupBy</option>
                                    {this.arrGroupBy.map((item, key) => {
                                        return <option key={key} value={item.value}>{item.name}</option>;
                                    })}
                         </select>&nbsp;&nbsp;&nbsp;
                         <button className="btn btn-ns-grey btn-sm" onClick={this.expandHandler.bind(this)}>
                            <i className='fa fa-expand'></i>&nbsp;&nbsp;&nbsp;Expand
                         </button>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        <button className="btn btn-ns-grey btn-sm" onClick={this.collapseHandler.bind(this)}>
                            <i className='fa fa-compress text-white'></i>&nbsp;&nbsp;&nbsp;Collapse
                        </button>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    </div>
              </div>
              <div className="box-body">
                  <NSGridReact setting={gridSetting} dataSource={[]} ref={(ref) => this.grid = ref} style={{"width": "100%"}}>
                  </NSGridReact>
              </div>
                
             {/*<NSMessageBoxReact setting={modalSetting} ref={(ref) => this.modal = ref} />
             <NSMessageBoxReact setting={modalSetting} ref={(ref) => this.modalEmployee = ref} />*/}
          </div>
               
        )
    }
              
    /*private getData(): void
    {
       this.grid.dataSource([]);
       const self : any = this;
       this.context.ajax.post(this.context.getURL("getFlatData"),{dataLength: 1000},null)
       .then(function(response){
           self.context.globalIntercept(response,function(response){
               if(self.grid)
               {
                    self.grid.dataSource(response)
               }
           });
       })
       .catch(err => this.context.globalCatchBlock(err));
    }*/

    private getData(): void
    {
        this.grid.dataSource([]);
        let param: any = {
                "operationName": "Countries",
                "variables": {},
                "query": this.commonUtil.normalize(CommonUtil.ALL_COUNTRIES)
                
           };
           this.context.ajax.post(CommonUtil.COUNTRIES_URL,param,null)
           .then(response => {
               if(this.grid && response && response.data && response.data.countries)
               {
                    this.grid.dataSource(response.data.countries);
               }
           })
           .catch(err => {
               this.context.globalCatchBlock(err);
           });
    }

    private expandHandler(event: any): void
    {
        this.grid.expandAll();
    }

    private collapseHandler(event: any): void
    {
        this.grid.collapseAll();
    }

    private handleGroupByChange(event: any): void
    {
        const groupBy: string = event.target.value;
        if(groupBy && groupBy != "-1")
        {
            this.grid.groupBy(groupBy);
        }
        this.setState({groupBy: groupBy})
    }
   
}

