import React from 'react';
import {BaseComponent} from '../modules/base/baseComponent';

export class Footer extends BaseComponent
{
    constructor(public props: any, public state: any) 
    {
        super(props,state);
    }
    
    componentDidMount() 
    {
        
    }
    
    render()
    {
        return (
               <footer className="nmPageFooter">
                    <strong>Copyright © 2021 <a href="nscomponent.com">NSComponent</a>.</strong> All rights reserved.
                </footer>               
        );
    }

    
    
  
}