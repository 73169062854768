
import React from 'react';

import { NSGridReact, INSGridColumn, INSGridSetting, INSGridDetailRendererComponent, INSGridDetailRendererComponentParam, INSGridCustomClassSetting } from 'nscomponentsreact';

interface ISourceItem {
    id: number;
    hierarchy: string; 
    supervisor: string;
    country: string;
    employees: string;
    price: number; 
    year: number;
    checked: boolean; 
    date: Date;
};

export const DetailGridRenderer = React.memo(React.forwardRef<any, INSGridDetailRendererComponent>((props, ref) => {
    const [data,setData] = React.useState({id: -1, country:"",hierarchy:""});

    const refCon = React.useRef<HTMLDivElement>(null);
    const refGrid = React.useRef<NSGridReact>(null);

    /* Component Editor Lifecycle methods */
    React.useImperativeHandle(ref, () => {
        return {
            init(param: INSGridDetailRendererComponentParam) {
                setData(param.masterData);
            },

            elementAdded(param: INSGridDetailRendererComponentParam) {
                console.log("elementAdded");
                const source: ISourceItem[] = generateSource(data);
                refGrid.current.dataSource(source);
            },
            renderEverytime(param: INSGridDetailRendererComponentParam) {
                if((param.rowIndex % 4) === 0) {
                    return true;
                }
                return false;
            }
        };
    });

    const generateSource = (masterItem: { country: string, hierarchy: string }) => {
        const totalRecords = 50;
        const arrItem: ISourceItem[] = [];
        for (let count = 0; count < totalRecords; count++) {
          const item = {
            id: count,
            hierarchy: masterItem.hierarchy + ' ' + count,
            supervisor: "Supervisor " + count,
            country: masterItem.country,
            employees: "EmployeesEmployeesEmployees" + count,
            price: (10 * count),
            year: 1985 + count,
            checked: false,
            date: new Date()
          };
          item.date.setFullYear(2015, Math.floor(Math.random() * 12), Math.floor(Math.random() * 27));
          item.date.setHours(Math.floor(Math.random()*23), Math.floor(Math.random()*59), Math.floor(Math.random()*59), 0);
          arrItem.push(item);
        }
        return arrItem;
    };

    const gridDetailColumn: INSGridColumn[] = [
        {headerText:"Id",dataField:"id",width:"200px",sortable:true,sortDescending:true,sortType:"number"},
        {headerText:"Country",dataField:"country",width:"200px",sortable:true,sortDescending:true},
        {headerText:"Employees",dataField:"employees",width:"300px",sortable:false,sortDescending:true,headerTruncateToFit:true,truncateToFit:true},
        {headerText:"Price",dataField:"price",toolTipField:"price",width:"300px",sortable:true,sortDescending:true},
        {headerText:"Hierarchy",dataField:"hierarchy",width:"300px",sortable:true,sortDescending:false},
        {headerText:"Year",dataField:"year",width:"200px",sortable:true,sortDescending:true}
    ];
    const customClass: INSGridCustomClassSetting = {headerCell:"columnClass",nonFirstBodyColumn:"columnClass"};
    const gridSetting: INSGridSetting = {type: NSGridReact.GRID_TYPE_NORMAL, 
        enableFilter: true, columns: gridDetailColumn, rowKeyField:"id", customClass: customClass};

    const style: Record<string,string> = {height: "100%", backgroundColor: "#EDF6FF", padding: "20px", boxSizing: "border-box"};
    return (
        <div ref={refCon} style={style}>
		    <div style={{height: "10%", padding: "2px", fontWeight: "bold"}}> 
                This Grid is for Id {data.id}
		    </div>
		    <div style={{}}>
                <NSGridReact setting={gridSetting} dataSource={[]} ref={refGrid} containerStyle={{"width": "100%", height: "350px"}}>
                </NSGridReact>
            </div>
		</div>
    );
}));

