import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GlobalContext } from '../globalContext'; // Adjust this import based on the actual path

const RoutesWrapper = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(GlobalContext); // Accessing the context

  useEffect(() => {
    // Function to handle history change
    const handleHistoryChange = (location) => {
      console.log("Navigated to:", location.pathname);
      // Implement your logic here, for example, tracking page views
    };

    // Example of setting up a listener for route changes
    // Since React Router v6 does not offer a direct history.listen equivalent,
    // you might need to mimic this behavior by tracking location changes
    // through the useEffect dependency array.
    handleHistoryChange(location);

    // Function to handle navigation blocking, adjust as needed
    // Note: React Router v6 does not support history.block directly.
    // You would need to implement custom logic or use a library that extends
    // React Router to support this feature.

    // Set up any global properties or methods
    // For example, providing a method to programmatically navigate
    context.routesWrapper = {
      pushState: (path) => navigate(path),
      // Add other methods or properties as needed
    };

    // Clean up function
    return () => {
      // If there's anything you need to clean up, do it here
      // For example, removing global methods or listeners
    };
  }, [navigate, location, context]);

  // Render children components passed to RoutesWrapper
  return <>{children}</>;
};

export default RoutesWrapper;