import * as React from "react";

import {BaseComponent} from '../base/baseComponent';
import { NSAjaxReact } from 'nscomponentsreact';
import { NSGridReact, INSGridReactSettings } from 'nscomponentsreact';
import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting, INSGridColumnEditorSetting } from 'nscomponentsreact';
//import { NSGrid} from 'nsgrid';
//import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client';
import {CommonUtil} from '../../util/commonUtil';
import {TextBoxEditor} from './components/textBoxEditor';

export class FlatGridDemo extends BaseComponent
{
    private commonUtil: CommonUtil;
    private grid: NSGridReact;
    constructor(public props: any, public state: any) 
    {        
        super(props,state);
        this.commonUtil = new CommonUtil();
        const customClass: INSGridCustomClassSetting = {headerCell:"columnClass",nonFirstBodyColumn:"columnClass"};
        
        /*const gridColumn: INSGridColumn[] = [
                                              {headerText: "First Name", dataField: "firstName", width: "20%",filter:{advancedFilterPopUpPos: "bottom-right"}},
                                              {headerText: "Last Name", dataField: "lastName", width: "20%",truncateToFit: true},
                                              {headerText: "City", dataField: "city", width: "15%",truncateToFit: true},
                                              {headerText: "Phone Number", dataField: "phoneNumber", width: "15%"},
                                              {headerText: "Birth Date", dataField: "birthDate", width: "20%"},
                                              {headerText: "Pet", dataField: "pet", width: "10%",enableFilter:false , sortable:false}
                                             ];*/
        const textEditorSetting: INSGridColumnEditorSetting = {type: NSGridReact.EDITOR_TYPE_CUSTOM ,customEditor: TextBoxEditor};
        const gridColumn: INSGridColumn[] = [
                                            {headerText:"",dataField:"isEdit",width:"10%",draggable:false,sortable:false,sortDescending:false,
                                            itemRenderer:this.editItemRenderer.bind(this),
                                              isExportable:false,showMenu:false,enableFilter:false,filter:{enableAdvancedFilter:false},enableEditable:false},
                                            {headerText: "Country", dataField: "name", width: "30%",filter:{advancedFilterPopUpPos: "bottom-right"},enableEditable:false},
                                            {headerText: "Native", dataField: "native", width: "20%",truncateToFit: true,enableEditable:false},
                                            {headerText: "Capital", dataField: "capital", width: "30%",truncateToFit: true,enableEditable:false},
                                            {headerText: "Phone Number", dataField: "phone", width: "15%",enableEditable:false},
                                            {headerText: "Continent", dataField: "continent.name", width: "20%",enableEditable:false},
                                            {headerText: "Currency", dataField: "currency", width: "10%",enableFilter:false , sortable:false,
                                             editorSetting: textEditorSetting}
                                          ];
        
        const editorSetting: INSGridEditorSetting = {editType:NSGridReact.EDITOR_EDITTYPE_ROW,clickType:NSGridReact.EDITOR_EDITING_NOCLICK,enableMultipleEdit:false};
        const gridSetting: INSGridReactSettings = {type: NSGridReact.GRID_TYPE_NORMAL, columnResizable: true,columnSortable:true,
              columnDraggable: false, enableFilter: true, enableAdvancedFilter: true, enableEditable: true,
              columns: gridColumn, customClass: customClass,rowKeyField:"code",heightOffset:170,
              enableVirtualScroll: true,theme: this.state.theme || this.context.theme,
              editorSetting: editorSetting};
        this.state = {
                theme: null,
                gridSetting: gridSetting,
        };
    
    }
    
    componentDidMount() 
    {     
        this.getData();
    }
    
    public themeChanged(event: any)
    {
        if(this.state)
        {
            this.state.theme = event.details.selectedTheme;    
        }
        if(this.grid)
        {
            this.grid.setTheme(this.state.theme);
        }
    }
    
    render() 
    {
        const buttonStyle: any = {"marginRight": "10px"};
        const gridSetting: INSGridReactSettings = this.state.gridSetting;
        return (
             <div className="box box-ns-red">
              <div className="box-header">
                    <h3 className="box-title">Flat Grid Demo</h3>
              </div>
              <div className="box-body">
                  <NSGridReact setting={gridSetting} dataSource={[]} ref={(ref) => this.grid = ref} style={{"width": "100%"}}>
                  </NSGridReact>
              </div>
          </div>
               
        )
    }

    editItemRenderer(item: any,dataField: string,rowIndex: number,columnIndex: number,row: any)
    {
      if(item)
        {
            var span = document.createElement("span");
            if(typeof item.isEdit == "undefined" || !item.isEdit)
            {
              var i = document.createElement("i");
                i.setAttribute("class","fa fa-pencil-square-o");
                i.setAttribute("title","Click here to Edit");
                i.addEventListener("click",(event) => {
                  item.isEdit = true;
                  this.grid.updateItemInDataSource(item);
                  this.grid.updateCellByKeyField(item["code"],dataField);
                  this.grid.editByItem(item);
                });
                span.appendChild(i);
            }
            else
            {
                
              var iCancel = document.createElement("i");
              iCancel.setAttribute("class","fa fa-undo");
              iCancel.setAttribute("title","Click here to Cancel");
              iCancel.style.marginRight = "10px";
              iCancel.addEventListener("click",(event) => {
                item.isEdit = false;
                this.grid.updateItemInDataSource(item);
                this.grid.updateCellByKeyField(item["code"],dataField);
                this.grid.editStopByItem(item,null,true);
              });
              span.appendChild(iCancel);
              
              var iSave = document.createElement("i");
              iSave.setAttribute("class","fa fa-save");
              iSave.setAttribute("title","Click here to Save");
              iSave.addEventListener("click",(event) => {
                item.isEdit = false;
                this.grid.updateItemInDataSource(item);
                this.grid.updateCellByKeyField(item["code"],dataField);
                this.grid.editStopByItem(item,null,false);
              });
              span.appendChild(iSave);
            }
            return span;
        }
        return null;
    }

    /*private getData(): void
    {
       this.grid.dataSource([]);
       const self : any = this;
       this.context.ajax.post(this.context.getURL("getFlatData"),{dataLength: 1000},null)
       .then(function(response){
           self.context.globalIntercept(response,function(response){
               if(self.grid)
               {
                    self.grid.dataSource(response)
               }
           });
       })
       .catch(err => this.context.globalCatchBlock(err));
       
       //this.grpahQLTest();
    }*/

    private getData(): void
    {
        this.grid.dataSource([]);
        let param: any = {
                "operationName": "Countries",
                "variables": {},
                "query": this.commonUtil.normalize(CommonUtil.ALL_COUNTRIES)
                
           };
           this.context.ajax.post(CommonUtil.COUNTRIES_URL,param,null)
           .then(response => {
               if(this.grid && response && response.data && response.data.countries)
               {
                    this.grid.dataSource(response.data.countries);
               }
           })
           .catch(err => {
               this.context.globalCatchBlock(err);
           });
    }
    
    /*private grpahQLTest()
    {
        console.log(gql);
        const client = new ApolloClient({
            uri: 'https://countries.trevorblades.com/',
            cache: new InMemoryCache(),
          });
        
        const ALL_COUNTRIES = gql`
        query Countries {
          countries {
            code
            name
            native
            phone
            continent {
              name
              code
            }
            capital
            currency
            languages {
               code
               name
               native
               rtl
            }
            emoji
            emojiU
            states {
                code
                name
            }
            
          }
        }
      `;
      
      client.query({
         query: ALL_COUNTRIES
       })
       .then(result => {
           console.log("All Countries are below");
           console.log(result);
       });
      
       const COUNTRIES_QUERY = gql`
          query Countries($code: String) {
              countries(filter: { continent: { eq: $code } }) {
            code
            name
            native
            phone
            continent {
              name
              code
            }
            capital
            currency
            languages {
               code
               name
               native
               rtl
            }
            emoji
            emojiU
            states {
                code
                name
            }
            
          }
        }
      `;
      
      const continentCode = 'EU';
      client.query({
         query: COUNTRIES_QUERY,
         variables: {
               code: continentCode
         }
       })
       .then(result => {
           console.log(continentCode + " Countries are below");
           console.log(result);
       });
      
       
    }*/
    
    /*private grpahQLAjax()
    {
        const ALL_COUNTRIES = `
        query Countries {
          countries {
            code
            name
            native
            phone
            continent {
              name
              code
            }
            capital
            currency
            languages {
               code
               name
               native
               rtl
            }
            emoji
            emojiU
            states {
                code
                name
            }
            
          }
        }
      `;
      
      console.log(ALL_COUNTRIES);
        
       let param: any = {
            "operationName": "Countries",
            "variables": {},
            //"query": "query Countries {\n  countries {\n    code\n    name\n    native\n    phone\n    continent {\n      name\n      code\n      __typename\n    }\n    capital\n    currency\n    languages {\n      code\n      name\n      native\n      rtl\n      __typename\n    }\n    emoji\n    emojiU\n    states {\n      code\n      name\n      __typename\n    }\n    __typename\n  }\n}\n"
            "query": this.normalize(ALL_COUNTRIES)
            
       };
       this.context.ajax.post("https://countries.trevorblades.com",param,null)
       .then(response => {
            console.log(response);
       })
       .catch(err => {
           console.log(err);
       });
    }*/
    
    
}

