import * as React from "react";
import { INSMessageBoxBodyComponent } from 'nscomponentsreact';
import {BaseComponent} from '../../base/baseComponent';


export class PetComponent extends BaseComponent implements INSMessageBoxBodyComponent
{
    private container: any;
    private element: HTMLElement;

    constructor(public props: any, public state: any) 
    {
        super(props,state);
        this.state = {
             url: null,
             value: null
        };
        //console.log(this.context);
    }
    
    componentDidMount() 
    {
        
    }
    
    init(data:any) : void
    {
        this.setState({
                url: data.url,
                value: data.name,
        });
    };
    
    getElement(): any
    {
        return this.container;
    };
    
    elementAdded(): void
    {
        
    };
    
   render() {
       const style = {width: "100%"}
       return (
            <div id="divImageCon" ref={(ref) => this.element = ref} style={style}>
                <img crossOrigin="anonymous" src={this.state.url} alt={this.state.value}></img>
           </div>
       )
   }
};
