import React from "react";
import { BrowserRouter, Routes } from 'react-router-dom';
import { Route } from 'react-router'
import {GlobalContextType, useGlobalContext} from '../globalContext'

import { Link} from 'react-router-dom';

import { NSNavigationReact, INSNavigationReactSettings } from 'nscomponentsreact';

import  Home  from "../../modules/home/home";
import { HierarchicalGridDemo } from "../../modules/grid/hierarchicalGridDemo";
import { GroupingGridDemo } from "../../modules/grid/groupingGridDemo";
import { FlatGridDemo } from "../../modules/grid/flatGridDemo";
import { RealTimeUpdates } from "../../modules/grid/realTimeUpdates";
import { Calendar } from "../../modules/calendar/calendar";
import { DatePicker } from "../../modules/datePicker/datePicker";
import { Panel } from "../../modules/panel/panel";
import { Dashboard } from "../../modules/dashboard/dashboard";
import { PageNotFound } from "../../modules/pageNotFound/pageNotFound";
import { NSReactEvent } from '../../modules/helper/nsReactEvent';
import {BaseComponent} from '../../modules/base/baseComponent';
import {default as RoutesWrapper} from './routesWrapper';
import { MasterDetailCustomGridDemo } from "../../modules/grid/masterDetailCustomGridDemo";
import { AutoComplete } from "../../modules/autocomplete/autocomplete";
import ExpressionEvaluator from "../../modules/expressionEvaluator/expressionEvaluator";
import MultiSelectDropdown from "../../modules/multiSelectDropdown/multiSelectDropdown";

export class Authenticate extends BaseComponent
{
    static contextType = useGlobalContext();
    context!: GlobalContextType; 
    private nsNavigationReact: NSNavigationReact;
    private selectedMenu:any = null;
    private divContent: HTMLDivElement; 
    private routesWrapper: any;
    private dataSource: any[] = [];
    private routes: any[];
    
    constructor(public props: any
            , public state: any) 
    {
        super(props,state);
        let navSetting: INSNavigationReactSettings = {header:"NAVIGATION",showCollapseIcon:true,iconCollapse:"<i class='fa fa-bars pull-right'></i>",titleField:"menuName",childField:"childMenus",iconPosition:"right",
                        iconMenuExpanded:"<i class='fa fa-angle-down'></i>",iconMenuCollapsed:"<i class='fa fa-angle-down expanded'></i>",collapseTopOffset:-10,routeType:"history"};
        this.state = {routes: null, navSetting:navSetting } ; 
    }
    
    componentDidMount() 
    {
        
    }
    
    componentDidUpdate()
    {
        
    }
    
    public initialize(): void
    {
        this.dataSource = [{menuName:"Home",link:"/home",iconBeforeHtml:"<i class='fa fa-home' aria-hidden='true'></i>",iconAfterHtml:null,selected: true},
                                 {menuName:"Grid Demo",link:"#",iconBeforeHtml:"<i class='fa fa-table' aria-hidden='true'></i>",iconAfterHtml:null,expanded:true,              
                                    childMenus:[{menuName:"Hierarchical",link:"/hierarchicalGrid",iconBeforeHtml:"<i class='fa fa-list-alt' aria-hidden='true'></i>",iconAfterHtml:null},        
                                                {menuName:"Grouping",link:"/groupingGrid",iconBeforeHtml:"<i class='fa fa-users' aria-hidden='true'></i>",iconAfterHtml:null,},                          
                                                {menuName:"Flat",link:"/flatGrid",iconBeforeHtml:"<i class='fa fa-table' aria-hidden='true'></i>",iconAfterHtml:null,},
                                                {menuName:"Real Time",link:"/realTimeGrid",iconBeforeHtml:"<i class='fa fa-random' aria-hidden='true'></i>",iconAfterHtml:null,},
                                                {menuName:"Master Detail",link:"/masterDetail",iconBeforeHtml:"<i class='fa fa-random' aria-hidden='true'></i>",iconAfterHtml:null,}
                                                ]},
                                 {menuName:"Calendar Demo",link:"#",iconBeforeHtml:"<i class='fa fa-calendar' aria-hidden='true'></i>",iconAfterHtml:null,expanded:true,              
                                    childMenus:[{menuName:"Calendar",link:"/calendar",iconBeforeHtml:"<i class='fa fa-calendar' aria-hidden='true'></i>",iconAfterHtml:null},        
                                                ]},
                                 {menuName:"DatePicker Demo",link:"#",iconBeforeHtml:"<i class='fa fa-calendar' aria-hidden='true'></i>",iconAfterHtml:null,expanded:true,              
                                    childMenus:[{menuName:"DatePicker",link:"/datePicker",iconBeforeHtml:"<i class='fa fa-calendar' aria-hidden='true'></i>",iconAfterHtml:null},        
                                                ]},
                                 {menuName:"Panel Demo",link:"#",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null,expanded:true,              
                                    childMenus:[{menuName:"Panel",link:"/panel",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null},        
                                                ]},
                                 {menuName:"Dashboard Demo",link:"#",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null,expanded:true,              
                                    childMenus:[{menuName:"Dashboard",link:"/dashboard",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null},        
                                                ]},
                                  {menuName:"Autocomplete Demo",link:"#",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null,expanded:true,              
                                                childMenus:[{menuName:"Autocomplete",link:"/autocomplete",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null},        
                                                ]},
                                  {menuName:"Expression Evaluator Demo",link:"#",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null,expanded:true,              
                                                childMenus:[{menuName:"Expression Evaluator",link:"/expressionEvaluator",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null},        
                                                ]},
                                  {menuName:"MultiSelect Dropdown Demo",link:"#",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null,expanded:true,              
                                                childMenus:[{menuName:"MultiSelect Dropdown",link:"/multiSelectDropdown",iconBeforeHtml:"<i class='fa fa-tachometer' aria-hidden='true'></i>",iconAfterHtml:null},        
                                                ]},
                                     
                               ];
        if(this.dataSource[0].childMenus && this.dataSource[0].childMenus.length > 0)
        {
            this.selectedMenu = this.dataSource[0].childMenus[0];
        }
        else
        {
            this.selectedMenu = this.dataSource[0];
        }
        if(this.nsNavigationReact)
        {
            this.nsNavigationReact["dataSource"](this.dataSource);
            this.routes = [{ path: '/home',component: Home},
                                 { path: '/hierarchicalGrid',component: HierarchicalGridDemo },
                                 { path: '/groupingGrid',component: GroupingGridDemo},
                                 { path: '/flatGrid',component: FlatGridDemo},
                                 { path: '/realTimeGrid',component: RealTimeUpdates},
                                 { path: '/calendar',component: Calendar},
                                 { path: '/datePicker',component: DatePicker},
                                 { path: '/panel',component: Panel},
                                 { path: '/dashboard',component: Dashboard},
                                 { path: '/masterDetail',component: MasterDetailCustomGridDemo},
                                 { path: '/autocomplete',component: AutoComplete},
                                 { path: '/expressionEvaluator',component: ExpressionEvaluator},
                                 { path: '/multiSelectDropdown',component: MultiSelectDropdown},
                                 { path: '*',component: PageNotFound,isDefault: true},
                               ];
            this.setState({routes: this.routes});
            this.navEventHandler(null,"navigationOpenStart");
            if(this.context.route)
            {
                this.selectRoute(this.context.route);
                this.context.route = null;
            }
        }
    }
    
    public toggleNavigation(): void
    {
        //this.nsNavigationReact.toggleNavigation();
        this.nsNavigationReact["toggleNavigation"]();
    }
    
    public themeChanged(event: any)
    {
        const currentTheme: string = event.details.selectedTheme;
        if(this.nsNavigationReact)
        {
            //this.nsNavigationReact.setTheme(currentTheme);
            this.nsNavigationReact["setTheme"](currentTheme);
        }
    }
    
    render() 
    {
        const routes = this.state.routes;
        const navSetting =  this.state.navSetting;
        const isLoggedIn = this.context.isLoggedIn;
        const isAuthorized = this.context.isAuthorized;
        const navEventHandler = this.navEventHandler.bind(this);
        const pageContent: any = {"margin": "5px","height": "100%"};
        const routeComponents = routes ? (routes.map(({path, component: Component,isDefault}, key) => {
            if(isDefault)
            {
                return <Route path="*" element={<Component />} key={key} />
            }
            else
            {
                return <Route path={path} element={<Component />}key={key} />
            }
        })) : null;
        /*const routeComponents = routes ? (routes.map(({path, compPath ,isDefault}, key) => {
            if(isDefault)
            {
                return <Route element= {
                  <Suspense fallback={<>...</>}>
                    { lazy(() => import("../../modules/home/home"))}
                  </Suspense>
                } key={key} />
            }
            else
            {
                return <Route path={path} element= {
                            <Suspense fallback={<>...</>}>
                            { lazy(() => import("../../modules/home/home"))}
                          </Suspense>
                        } key={key} />
            }
        })) : null;*/
        const navigationStyle = {"marginTop": "50px","width": "250px"};
        const basename: string = this.context.baseName;
        if(isLoggedIn && !isAuthorized)
        {
            return (
                <React.Fragment>
                    <div className="box box-danger box-border screen-center-box">
                        <div className="box-header with-border">
                            <i className="fa fa-ban text-ns-red"></i>
                            <h3 className="box-title">Unauthorized</h3>
                        </div>
                        <div className="box-body">
                            You are not authorized to access this application.
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else if(isLoggedIn && isAuthorized)
        {
            return (
                <React.Fragment>
                    <NSNavigationReact containerStyle={navigationStyle} setting={navSetting} 
                               ref={(ref) => this.nsNavigationReact = ref} onNavigationOpenStart={navEventHandler} 
                               onNavigationCloseStart={navEventHandler} onNavigationMenuSelected={navEventHandler} />
                    <div id="divContent" className="nmPageContent" style={pageContent} ref={(ref)=> this.divContent = ref}>
                        <BrowserRouter basename={basename}>
                            <RoutesWrapper>
                                <Routes>
                                    {routeComponents}
                                </Routes>
                            </RoutesWrapper>
                        </BrowserRouter>
                    </div>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <React.Fragment>
                    <div className="loader">
                        <div className="loader-centered">
                            <div className="object square-one"></div>
                            <div className="object square-two"></div>
                            <div className="object square-three"></div>
                        </div>
                    </div>
                    <div className="container bootstrap snippets bootdeys">
                        <div className="jumbotron">
                            <h1 id="loading-text">Loading...</h1>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
       
    }
    
    private __removeSlashes(path: string)
    {
        return path.toString().replace(/\/$/, "").replace(/^\/+/, "");
    };

    private navEventHandler(event: any,eventType: string): void
    {
        console.log(event);
        //let divContent: HTMLDivElement = this.nsNavigationReact.getElement();
        eventType = eventType || event.type;
        switch(eventType)
        {
          case "navigationOpenStart":
            if(this.divContent)
            {
            	this.divContent.style.marginLeft = "250px";
            }
          break;
          case "navigationCloseStart":
            if(this.divContent)
            {
              this.divContent.style.marginLeft = "50px";
            }
          break;
          case "navigationMenuSelected":
            const item:any = event["detail"];
          //this.routesWrapper not working
            if(this.context.routesWrapper)// && (!this.selectedMenu || this.selectedMenu.link != item.link)
            {
                this.context.routesWrapper.pushState(item.link);
                this.selectedMenu = item;
            }
            //let compMenuHeader = this.__compMenuHeader.nativeElement;
            //compMenuHeader.innerHTML = item.menuName;
          break;
        }
    }
    
    private selectRoute(route: string)
    {
        const tempRoute: string = "/" + route;
        for (let item of this.routes) 
        {
            if(!item.isDefault && item.path == tempRoute)
            {
                this.selectMenu(route);
                //this.context.routesWrapper.pushState(tempRoute);
            }
        }
    }
    
    private selectMenu(routePath: string): void
    {
        let item: any = this.nsNavigationReact["__nsNavigation"].getItemByField("link","/" + routePath);
        if(item)
        {
            this.nsNavigationReact.selectMenu(item);
        }
    }

    /*private resizeListener(event:any)
    {
        //let resizeEvent:any = new Event('resize');
        let resizeEvent:any = document.createEvent("HTMLEvents");
        resizeEvent.initEvent("resize", true, false);
        window.dispatchEvent(resizeEvent);
    }*/
    

   
   
}