export class CommonUtil
{
    public static ALL_COUNTRIES = `
        query Countries {
        countries {
          code
          name
          native
          phone
          continent {
            name
            code
          }
          capital
          currency
          languages {
             code
             name
             native
             rtl
          }
          emoji
          emojiU
          states {
              code
              name
          }
          
        }
      }
    `;
    
    public static COUNTRIES_URL = "https://countries.trevorblades.com";
    
    public normalize(string: string) 
    {
        return string.replace(/[\s,]+/g, ' ').trim();
    }
}