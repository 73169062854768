import React, { useState, useEffect } from 'react';
import {NSMultiselectDropdownReact, INSMultiSelectDropdownReactSetting} from 'nscomponentsreact';

interface IState {
  name: string;
  abbreviation: string;
}

const MultiSelectDropdown: React.FC = () => {

    const nsDropdownSimpleRef = React.useRef<NSMultiselectDropdownReact>(null);
    const nsDropdownVirtualRef = React.useRef<NSMultiselectDropdownReact>(null);
    const nsDropdownHorizontalListRef = React.useRef<NSMultiselectDropdownReact>(null);
    const nsDropdownVerticalListRef = React.useRef<NSMultiselectDropdownReact>(null);
    const nsDropdownMaxItemsRef = React.useRef<NSMultiselectDropdownReact>(null);
    const nsDropdownCustomLabelRef = React.useRef<NSMultiselectDropdownReact>(null);

    const [selectedMaxItems, setSelectedMaxItems] = useState<number>(5);
    const [selectedStates, setSelectedStates] = useState<IState[] | null>(null);

    useEffect(() => {
        simpleUsage();
        virtualList();
        labelHorizontalList();
        labelVerticalList();
        customLabelPosTop();
    }, []);

    useEffect(() => {
        console.log(nsDropdownMaxItemsRef?.current);
    });

    useEffect(() => {
        setTimeout(() => {
            if(nsDropdownMaxItemsRef.current) {
                labelMaxItems();
            }
        }, 500);
    }, [nsDropdownMaxItemsRef, selectedMaxItems])

    const getSetting = (labelField: string, placeHolder: string = 'Search State(s)', otherProps: INSMultiSelectDropdownReactSetting = {}): INSMultiSelectDropdownReactSetting => {
        const setting: INSMultiSelectDropdownReactSetting = {...{labelField: labelField,listWidth:"500px",listHeight:"400px",placeHolder: placeHolder}, ...otherProps};
        return setting
    };

    const simpleUsage = () => {
        //nsDropdownSimpleRef.current?.dataSource(states);
    };

    const virtualList = () => {
        const source = [];
        for (let i = 0; i < 10000; i++) 
        {
            source.push({label:"Item " + (i+1),index:i});
        }
        nsDropdownVirtualRef.current?.dataSource(source);
    };

    const labelHorizontalList = () => {
        nsDropdownHorizontalListRef.current?.dataSource(states);
    };

    const labelVerticalList = () => {
        nsDropdownVerticalListRef.current?.dataSource(states);
    };

    const labelMaxItems = () => {
        nsDropdownMaxItemsRef.current?.dataSource(states);
    };

    const customLabelPosTop = () => {
        nsDropdownCustomLabelRef.current?.dataSource(states);
    };

    const handleMaxItemsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMaxItems(parseInt(event.target.value, 10));
    };

    const changeHandler = (event: any, nsDropdown: React.MutableRefObject<NSMultiselectDropdownReact>) => {
        const selectedItems:IState[] | any[] = (nsDropdown.current?.getSelectedItems() as IState[] | any[]);
        console.log("Selected Items are ", selectedItems);
    }

    const labelRenderer = (arrItems: any[],arrIndexes: number[],arrTexts: string[],labelField:string): HTMLElement | string => {
        if(arrItems && arrItems.length > 0)
        {
            const ul = document.createElement("ul");
            ul.classList.add("listCon");
            for(var count = 0;count < arrItems.length;count++)
            {
                var item = arrItems[count];
                var li = document.createElement("li");
                var div = document.createElement('div');
                div.classList.add("text");
                div.appendChild(document.createTextNode(arrTexts[count]));
                li.appendChild(div);
                var span = document.createElement("span");
                span.classList.add("close");
                span.innerHTML = "&times;";
                span.setAttribute("data-index",'' + arrIndexes[count]);
                span.addEventListener("click",closeItem.bind(null,item,arrIndexes[count]));
                li.appendChild(span);
                ul.appendChild(li);
            }
            return ul;
        }
        return "";
    };

    const closeItem = (item: any,index: number,event: React.MouseEvent<HTMLSpanElement>) => {
        console.log(item, index);
        nsDropdownCustomLabelRef.current?.setSelectUnselectItems(item,false);
        event.stopPropagation();
    };

    const selectValue = () => {
        const values = [{ name: 'MICHIGAN', abbreviation: 'MI'},{ name: 'NEW JERSEY', abbreviation: 'NJ'}];
        setSelectedStates([...values]);
    };

    return (
        <div className="container">
        <h1>MultiSelect Dropdown</h1>
        <h3>Simple Usage with Multiple Select:</h3>
        <div style={{display: "flex", gap: "20px"}}>
            <div style={{ width: '200px' }}>
                <NSMultiselectDropdownReact 
                    ref={nsDropdownSimpleRef} 
                    setting={getSetting('name')} 
                    style={{width:"100%"}}
                    dataSource={states}
                    value={selectedStates}
                    onClose={e => changeHandler(e, nsDropdownSimpleRef)}>
                </NSMultiselectDropdownReact>
            </div>
            <button type="button" onClick={selectValue}>Select MICHIGAN and NEW JERSEY</button>
        </div>

        <h3>Multiple Select with Virtual List:</h3>
        <div style={{ width: '200px' }}>
            <NSMultiselectDropdownReact 
                ref={nsDropdownVirtualRef} 
                setting={getSetting('label', 'Select Item(s)',{enableVirtualScroll:true})} 
                style={{width:"100%"}} 
                onClose={e => changeHandler(e, nsDropdownVirtualRef)}>
            </NSMultiselectDropdownReact>
        </div>

        <h3>Multiple Select Label as Horizontal List:</h3>
        <div style={{ width: '400px' }}>
            <NSMultiselectDropdownReact 
                ref={nsDropdownHorizontalListRef} 
                setting={getSetting('name', 'Select State(s)',{labelType: NSMultiselectDropdownReact.LABEL_TYPE_HORIZONTAL_LIST})} 
                style={{width:"100%"}} 
                onClose={e => changeHandler(e, nsDropdownHorizontalListRef)}>
            </NSMultiselectDropdownReact>
        </div>

        <h3>Multiple Select Label as Vertical List:</h3>
        <div style={{ width: '200px' }}>
            <NSMultiselectDropdownReact 
                ref={nsDropdownVerticalListRef} 
                setting={getSetting('name', 'Select State(s)',{labelType: NSMultiselectDropdownReact.LABEL_TYPE_VERTICAL_LIST})} 
                style={{width:"100%"}} 
                onClose={e => changeHandler(e, nsDropdownVerticalListRef)}>
            </NSMultiselectDropdownReact>
        </div>

        <h3>Multiple Select with max items:</h3>
        <div>
            <label htmlFor="cmbStates">Choose Max States to be selected: </label>
            <select name="cmbStates" id="cmbStates" value={selectedMaxItems} onChange={handleMaxItemsChange}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
            </select>
        </div>
        <div style={{ width: '200px' }}>
            <NSMultiselectDropdownReact 
                key={selectedMaxItems}
                ref={nsDropdownMaxItemsRef} 
                setting={getSetting('name', 'Select State(s)',{labelType: NSMultiselectDropdownReact.LABEL_TYPE_VERTICAL_LIST, itemAllowedToBeSelected: selectedMaxItems})} 
                style={{width:"100%"}} 
                onClose={e => changeHandler(e, nsDropdownMaxItemsRef)}>
            </NSMultiselectDropdownReact>
		</div>

        <h3>Multiple Select Demo with Position of List at Top and Custom Label:</h3>
        <div style={{ width: '200px' }}>
            <NSMultiselectDropdownReact 
                ref={nsDropdownCustomLabelRef} 
                setting={getSetting('name', 'Select State(s)',{labelRenderer: labelRenderer,showDropDownIcon: false,position:'top'})} 
                style={{width:"100%"}} 
                onClose={e => changeHandler(e, nsDropdownCustomLabelRef)}>
            </NSMultiselectDropdownReact>
        </div>
        </div>
    );
};

const states: IState[] = [
    { name: 'ALABAMA', abbreviation: 'AL'},
    { name: 'ALASKA', abbreviation: 'AK'},
    { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
    { name: 'ARIZONA', abbreviation: 'AZ'},
    { name: 'ARKANSAS', abbreviation: 'AR'},
    { name: 'CALIFORNIA', abbreviation: 'CA'},
    { name: 'COLORADO', abbreviation: 'CO'},
    { name: 'CONNECTICUT', abbreviation: 'CT'},
    { name: 'DELAWARE', abbreviation: 'DE'},
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
    { name: 'FLORIDA', abbreviation: 'FL'},
    { name: 'GEORGIA', abbreviation: 'GA'},
    { name: 'GUAM', abbreviation: 'GU'},
    { name: 'HAWAII', abbreviation: 'HI'},
    { name: 'IDAHO', abbreviation: 'ID'},
    { name: 'ILLINOIS', abbreviation: 'IL'},
    { name: 'INDIANA', abbreviation: 'IN'},
    { name: 'IOWA', abbreviation: 'IA'},
    { name: 'KANSAS', abbreviation: 'KS'},
    { name: 'KENTUCKY', abbreviation: 'KY'},
    { name: 'LOUISIANA', abbreviation: 'LA'},
    { name: 'MAINE', abbreviation: 'ME'},
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
    { name: 'MARYLAND', abbreviation: 'MD'},
    { name: 'MASSACHUSETTS', abbreviation: 'MA'},
    { name: 'MICHIGAN', abbreviation: 'MI'},
    { name: 'MINNESOTA', abbreviation: 'MN'},
    { name: 'MISSISSIPPI', abbreviation: 'MS'},
    { name: 'MISSOURI', abbreviation: 'MO'},
    { name: 'MONTANA', abbreviation: 'MT'},
    { name: 'NEBRASKA', abbreviation: 'NE'},
    { name: 'NEVADA', abbreviation: 'NV'},
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
    { name: 'NEW JERSEY', abbreviation: 'NJ'},
    { name: 'NEW MEXICO', abbreviation: 'NM'},
    { name: 'NEW YORK', abbreviation: 'NY'},
    { name: 'NORTH CAROLINA', abbreviation: 'NC'},
    { name: 'NORTH DAKOTA', abbreviation: 'ND'},
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
    { name: 'OHIO', abbreviation: 'OH'},
    { name: 'OKLAHOMA', abbreviation: 'OK'},
    { name: 'OREGON', abbreviation: 'OR'},
    { name: 'PALAU', abbreviation: 'PW'},
    { name: 'PENNSYLVANIA', abbreviation: 'PA'},
    { name: 'PUERTO RICO', abbreviation: 'PR'},
    { name: 'RHODE ISLAND', abbreviation: 'RI'},
    { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
    { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
    { name: 'TENNESSEE', abbreviation: 'TN'},
    { name: 'TEXAS', abbreviation: 'TX'},
    { name: 'UTAH', abbreviation: 'UT'},
    { name: 'VERMONT', abbreviation: 'VT'},
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
    { name: 'VIRGINIA', abbreviation: 'VA'},
    { name: 'WASHINGTON', abbreviation: 'WA'},
    { name: 'WEST VIRGINIA', abbreviation: 'WV'},
    { name: 'WISCONSIN', abbreviation: 'WI'},
    { name: 'WYOMING', abbreviation: 'WY' }
];

export default MultiSelectDropdown;