import * as React from "react";
import { INSGridRendererComponent, INSGridEditorCustomComponentSetting } from 'nscomponentsreact';
import { NSMessageBoxReact, INSMessageBoxReactSettings } from 'nscomponentsreact';
import { INSMessageBoxCustomSetting } from 'nscomponentsreact';
import {BaseComponent} from '../../base/baseComponent';

export class CountryRenderer extends BaseComponent implements INSGridRendererComponent
{
    private element: any;

    constructor(public props: any, public state: any) 
    {
        super(props,state);
        this.state = {item: null};
    }
    
    componentDidMount() 
    {
        //console.log(this.context);
    }
    
    setData(data: any,dataField: string,index: number,colIndex: number,row: any): void
    { 
        const url: string = "https://flagcdn.com/h20/" + data["countryIcon"] + ".png"
        this.setState({item: data,url: url,value: data[dataField]});
        //console.log(this.context);
    };
    
    getElement(): any
    {
        return this.element;
    };
        
   render() 
   {
       const style = {width: "100%"};
       return (
               <span ref={(ref) => this.element = ref} style={style}>
                   <img width='15' height='10' style={{marginBottom: "2px"}} src={this.state.url} alt={this.state.value} />
                   &nbsp;&nbsp;{this.state.value}
              </span>
       )
   }

   
}
