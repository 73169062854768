import * as React from "react";

import {BaseComponent} from '../base/baseComponent';
import { NSAjaxReact } from 'nscomponentsreact';
import { NSGridReact, INSGridReactSettings } from 'nscomponentsreact';
import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting } from 'nscomponentsreact';

import { CountryRenderer } from './components/countryRenderer';
import { ProficiencyRenderer } from './components/proficiencyRenderer';
import { RealTimeRenderer } from './components/realTimeRenderer';

import {DataGenerator} from '../../util/dataGenerator';

export class RealTimeUpdates extends BaseComponent
{
    private grid: NSGridReact;
    private dataGenerator: DataGenerator;

    constructor(public props: any, public state: any) 
    {        
        super(props,state);
        const customClass: INSGridCustomClassSetting = {headerCell:"columnClass",nonFirstBodyColumn:"columnClass"};
        
        const gridColumn: INSGridColumn[] = [
                                              {headerText: "Name", dataField: "name", width: "200px",filter:{advancedFilterPopUpPos: "bottom-right"}},
                                              {headerText: "Country", dataField: "country", width: "150px",truncateToFit: true,
                                               itemRendererComponent: CountryRenderer},
                                              {headerText: "Language", dataField: "language", width: "150px",truncateToFit: true},
                                              {headerText: "Asset", dataField: "asset", width: "180px",itemRendererComponent: RealTimeRenderer},
                                              {headerText: "Proficiency", dataField: "proficiency", width: "150px",itemRendererComponent: ProficiencyRenderer},
                                              {headerText: "Phone", dataField: "phoneNumber", width: "180px"},
                                              
                                             ];
        
        const editorSetting: INSGridEditorSetting = {clickType:NSGridReact.EDITOR_EDITING_NOCLICK,enableMultipleEdit:true};
        const gridSetting: INSGridReactSettings = {type: NSGridReact.GRID_TYPE_NORMAL, columnResizable: true,columnSortable:true,
                columnDraggable: false, enableFilter: true, enableAdvancedFilter: true,
               columns: gridColumn, customClass: customClass,rowKeyField:"id",heightOffset:170,
               enableVirtualScroll: true,theme: this.state.theme || this.context.theme};
        this.state = {
                theme: null,
                gridSetting: gridSetting,
        };
        this.dataGenerator = new DataGenerator();
    }
    
    componentDidMount() 
    {     
        this.getData();
    }
    
    public themeChanged(event: any)
    {
        if(this.state)
        {
            this.state.theme = event.details.selectedTheme;    
        }
        if(this.grid)
        {
            this.grid.setTheme(this.state.theme);
        }
    }
    
    render() 
    {
        const buttonStyle: any = {"marginRight": "10px"};
        const gridSetting: INSGridReactSettings = this.state.gridSetting;
        return (
             <div className="box box-ns-red">
              <div className="box-header">
                    <h3 className="box-title">Real Time Grid Demo</h3>
              </div>
              <div className="box-body">
                  <NSGridReact setting={gridSetting} dataSource={[]} ref={(ref) => this.grid = ref} style={{"width": "100%"}}>
                  </NSGridReact>
              </div>
          </div>
               
        )
    }

    private getData(): void
    {
       this.grid.dataSource(this.dataGenerator.getRealTimeUpdates(20));
       this.refreshGrid();
    }
    
    private refreshGrid(): void
    {
        let self = this;
        setInterval(() => {
            if(self.grid)
            {
                let arrItem: any[] = self.grid.getFilteredData();
                for(let item of arrItem) 
                {
                    item.prevAsset = item.asset;
                    item.asset = self.dataGenerator.changeAsset();
                    item.prevProficiecy = item.proficiency;
                    item.proficiency = self.dataGenerator.changeProficiency();
                    let index: number = self.grid.getItemInfo(item).rowIndex;
                    self.grid.updateCellByIndex(index,"asset");
                    self.grid.updateCellByIndex(index,"proficiency");
                }
            }
        }, 2000);
    }
}

