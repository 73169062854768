import * as React from "react";
import {GlobalContextType, useGlobalContext} from '../../app/globalContext';
import { NSReactEvent } from '../helper/nsReactEvent';

export class BaseComponent extends React.Component<any, any>
{
    static contextType = useGlobalContext();
    context!: GlobalContextType; 
    
    constructor(public props: any, public state: any) 
    {
        super(props,state);
        NSReactEvent.getInstance().addListener("themeChanged",this.themeChanged.bind(this));
    }
    
    public themeChanged(event: any)
    {
        
    }
    
   render() {
        return (
          <div>
          </div>
        )
   }

}
